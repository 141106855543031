"use strict";

angular.module("informaApp")
    .service("CompanyProfileService", ["BaseApiService", "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        return {
            getCompanyProfile: function(drugIndicationsIds, startDate, endDate){
                return BaseApiService.post('companies/profile', {
                    ids: drugIndicationsIds,
                    startDate: startDate,
                    endDate: endDate
                });                
                /*
                return $http.post(ConstantsSvc.API.URL + "companies/profile", {
                    ids: drugIndicationsIds,
                    startDate: startDate,
                    endDate: endDate
                });
                */
            }
        };
    }]);